<template>
  <div class="box">
    <div class="box-header with-border">
      <h3>
        <previous-route-block/>

        {{ page_title }}
      </h3>

    </div>

    <div class="box-body">
      <table-items :data_search="data_search"
                   :relations="relations"
                   :relations_count="relations_count"
                   component="utm-tag-item"
                   :columns="columns"
                   api_resource="/tasks/utm_tags">
      </table-items>
    </div>
  </div>

</template>

<script>
import Vue from 'vue';
import TableItems from './../../../components/pagination/table-items';

Vue.component('utm-tag-item', (resolve) => {
  return require(['./item'], resolve);
});

export default {
  name: 'utm_tags',

  components: {
    TableItems,
  },

  props: {
    data_search: {
      type: String,
      required: false
    }
  },

  metaInfo() {
    return {
      title: this.page_title,
    }
  },

  data: () => ({
    page_title: 'UTM данные',

    columns: [
      {
        title: 'Дата',
        class: null,
        style: null,
      },
      {
        title: 'Задача',
        class: null,
        style: null,
      },
      {
        title: 'Клиент',
        class: null,
        style: null,
      },
      {
        title: 'Source',
        class: null,
        style: null,
      },
      {
        title: 'Medium',
        class: null,
        style: null,
      },
      {
        title: 'Campaign',
        class: null,
        style: null,
      },
    ],
    relations: [
      'editor',
    ],
    relations_count: [
      'clients',
    ],
  }),

  methods: {
    updateFilters() {
      this.EventBus.$emit('table-filter-changed');
    },
  },
}
</script>

<style scoped>

</style>
